import React from 'react'

const DeliveryDateWarning = () => {
  return (
    <div className='w-full p-10'>
      Delivery Date Exceeded...!
    </div>
  )
}

export default DeliveryDateWarning
